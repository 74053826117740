import { platformAdapter, resultSchema } from '@empathyco/x-adapter-platform';
import { Result } from '@empathyco/x-types';

export const adapter = platformAdapter;

/* Code sample about how to extend the result mapper with more fields. */

interface EmpathyDemoPlatformResult {
  __id: string;
  __name: string;
  __images: string[];
  __url: string;
  __externalId: string;
  __prices: {
    current: {
      value: number;
    };
    previous?: {
      value: number;
    };
    future?: {
      value: number;
    };
  };
  description: string;
  privateUrl: string;
  tagging: {
    add2cart: string;
    checkout: string;
    click: string;
  };
  subcategory: string;
}

declare module '@empathyco/x-types' {
  export interface Result {
    description: string;
    subcategory: string;
  }
}

// TODO: Remove test content

resultSchema.$override<EmpathyDemoPlatformResult, Partial<Result>>({
  id: '__id',
  name: '__name',
  images: '__images',
  identifier: {
    value: '__externalId'
  },
  price: ({ __prices: rawPrices }) => {
    const currentValue = rawPrices?.current?.value;

    return {
      value: currentValue,
      originalValue: rawPrices?.previous?.value ?? currentValue,
      hasDiscount: currentValue < (rawPrices?.previous?.value ?? currentValue),
      futureValue: rawPrices?.future?.value ?? currentValue
    };
  },
  description: 'description',
  url: ({ __url, privateUrl }) => privateUrl ?? __url,
  subcategory: 'subcategory'
});
