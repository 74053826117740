










  import { SnippetCallbacks, SnippetConfig, XOn, XProvide } from '@empathyco/x-components';
  import { QueryPreviewInfo } from '@empathyco/x-components/queries-preview';
  import { Tagging } from '@empathyco/x-components/tagging';
  import { UrlHandler } from '@empathyco/x-components/url';
  import { SnippetConfigExtraParams } from '@empathyco/x-components/extra-params';
  import { Component, Inject, Vue, Watch } from 'vue-property-decorator';
  import { Dictionary } from '@empathyco/x-utils';
  import { useDevice } from './composables/use-device.composable';
  import currencies from './i18n/currencies';
  import './tailwind/index.css';

  @Component({
    components: {
      SnippetCallbacks,
      SnippetConfigExtraParams,
      Tagging,
      UrlHandler,
      MainModal: () => import('./components/custom-main-modal.vue').then(m => m.default)
    }
  })
  export default class App extends Vue {
    protected isOpen = false;

    @XOn(['UserOpenXProgrammatically', 'UserClickedOpenX'])
    open(): void {
      this.isOpen = true;
    }

    @Inject('snippetConfig')
    protected snippetConfig!: SnippetConfig;
    protected device = useDevice();

    protected get documentDirection(): string {
      return (
        document.documentElement.dir ||
        document.body.dir ||
        (this.snippetConfig.documentDirection ?? 'ltr')
      );
    }

    protected get isLogged(): boolean {
      return this.snippetConfig.logged;
    }

    protected get searchSegment(): string[] {
      const { searchSegment } = this.snippetConfig;
      if (!searchSegment) {
        return ['Home'];
      } else {
        return Array.isArray(searchSegment) ? [...searchSegment, 'Home'] : [searchSegment, 'Home'];
      }
    }

    protected get snippetValues(): Dictionary {
      if (this.isLogged) {
        Vue.set(this.snippetConfig, 'searchSegment', this.searchSegment);
      }
      return this.snippetConfig;
    }

    @XProvide('currencyFormat')
    public get currencyFormat(): string {
      return currencies[this.snippetConfig.currency!];
    }

    @XProvide('queriesPreviewInfo')
    public get queriesPreviewInfo(): QueryPreviewInfo[] | undefined {
      return this.snippetConfig.queriesPreview ?? [];
    }

    @Watch('snippetConfig.uiLang')
    syncLang(uiLang: string): void {
      this.$setLocale(uiLang);
    }

    @Watch('device.deviceName')
    syncDevice(deviceName: string): void {
      this.$setLocaleDevice(deviceName);
    }
  }
